export default [
  {
    id: "home",
    not_feather: true,
    icon: "mdi mdi-home",
    title: "Home",
    to: "/home",
  },
  // user
  {
    id: "user",
    not_feather: true,
    icon: "mdi mdi-account-multiple",
    title: "Users",
    name: "/users/",
    children: [
      {
        title: "Admin",
        permissions: ['developer','admin'],
        to: "/user/admin",
      },
      {
        title: "Vettori",
        permissions: ['developer','admin'],
        to: "/user/vectors",
      },
      {
        title: "Operatori",
        permissions: ['*'],
        to: "/user/operators",
      }
    ]
  },
  {
    id: "vehicle",
    not_feather: true,
    icon: "mdi mdi-ambulance",
    title: "Veicoli",
    to: "/vehicles",
  },
  {
    id: "type-vehicles",
    not_feather: true,
    icon: "mdi mdi-car-connected",
    title: "Tipologia Veicoli",
    to: "/type-of-vehicles",
    permissions: ['developer','admin'],
  },
  {
    id: "tags",
    not_feather:true,
    icon: "pl-2 mr-1 fas fa-tags",
    title: "Tags",
    to: "/tags",
    permissions: ['developer','admin'],
  },
  {
    id: "services",
    not_feather:true,
    icon: "mdi mdi-google-circles-extended",
    title: "Servizi",
    to: "/type-of-services",
    permissions: ['developer','admin'],
  },
  {
    id: "patient",
    not_feather:true,
    icon: "mdi mdi-human-male-female",
    title: "Pazienti",
    to: "/patients",
  },
  {
    id: "reservation",
    not_feather: true,
    icon: "mdi mdi-calendar",
    title: "Prenotazioni",
    name: "/track-reservation/",
    children: [
      {
        title: "Crea",
        to: "/track-reservation/create",
      },
      {
        title: "Nuove",
        to: "/track-reservations/new",
      },
      {
        title: "Storico",
        to: "/track-reservations/history",
      }
    ]
  },
];
