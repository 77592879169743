<template>
  <b-navbar-brand >
       <!-- Logo icon -->
        <b class="logo-icon">
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
          <img src="../../../assets/images/logo-niino-light.svg" alt="homepage" >
            
        </b>
        <!--End Logo icon -->
        <!-- Logo text -->
        <span class="logo-text">
            <!-- dark Logo text -->
        </span>
  </b-navbar-brand>
</template>

<script>
export default {
    name: 'LogoLight',
}
</script>
